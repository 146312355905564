/** @format */
import { Component as ReactComponent } from "react";

import { Box, styled } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import Broadcast from "broadcast";
import Capture from "capture";
import Chat from "chat";
import Cms from "cms";
import Constraints from "constraints";
import Datattrib from "datattrib";
import Errors, { FatalError } from "errors";
import Locales from "locales";
import MadeIdle from "madeidle";
import Maintenance from "maintenance";
import Notifications from "notifications";
import Performing from "performing";
import Profiles from "profiles";
import Snackbar from "snackbar";
import UI, { ThemeProvider, light } from "ui";
import Unit from "unit";
import User from "user";

// import Offline from "offline";
import Router from "router";
import Search from "search";

// Warns while languages are in development
import LanguageWarning from "../Language";

const StyledApp = styled(Box)(() => ({
	"& .SnackbarContainer-bottom": { marginBottom: "-10px" },
}));

class App extends ReactComponent {
	constructor(props) {
		super(props);
		Datattrib.set({ name: "version", value: import.meta.env.VITE_VERSION });
	}

	render() {
		return (
			<Unit>
				<MadeIdle>
					<ThemeProvider theme={light}>
						<CssBaseline />
						<StyledApp>
							<Performing>
								<Locales>
									<Snackbar>
										<Errors>
											<FatalError>
												<Maintenance>
													<UI>
														<Constraints>
															<User>
																<Profiles>
																	<Broadcast>
																		<Notifications>
																			<Chat>
																				<Capture>
																					<Cms>
																						<Search>
																							{/* <Offline> */}
																							<Router />
																							<LanguageWarning />
																							{/* </Offline> */}
																						</Search>
																					</Cms>
																				</Capture>
																			</Chat>
																		</Notifications>
																	</Broadcast>
																</Profiles>
															</User>
														</Constraints>
													</UI>
												</Maintenance>
											</FatalError>
										</Errors>
									</Snackbar>
								</Locales>
							</Performing>
						</StyledApp>
					</ThemeProvider>
				</MadeIdle>
			</Unit>
		);
	}
}

export default App;
